import BaseModel from "./BaseModel";
import Image from "./Image";

export class MenuItem extends BaseModel {

    private _id: number = -1;
    private _id_image: number = -1;
    private _image: Image = new Image();
    private _item_order: number = -1;
    private _linkType: number = 0;
    private _parent: number = -1;
    private _name: string = "";
    private _link: string = "";
    private _icon: string = "";
    private _title: string = "";
    private _class: string = "";
    private _text: string = "";
    private _childs: MenuItem[] = [];

    public getLinkWithSlashAndRedirect(replace: string, redirectTo: string) {
        return this.link === replace ? redirectTo : this.getLinkWithSlash();
    }

    public hasLink(includeIndex: boolean = false): boolean {
        return (this.link.length > 0 || includeIndex) && this.link !== null && this.link !== "new_menu_item";
    }

    public getLinkWithSlash(): string {
        if (this.link && this.link.indexOf("http") > -1) {
            return this.link;
        } else if (this.link && this.link.length > 1 && this.link.charAt(0) === "/") {
            return this.link;
        }
        return `/${this.link}`;
    }

    public isHttpLink(): boolean {
        return this.link !== null && this.link !== undefined && this.link.indexOf("http") > -1;
    }

    public hasChilds(): boolean {
        return this.childs.length > 0;
    }

    hasImage(): boolean {
        return this.id_image > 0;
    }

    getPhoneLink(): string {
        return `tel:${this.link}`;
    }

    getMailToLink(): string {
        return `mailto:${this.link}`;
    }

    public fromObject(jsonObj: object): BaseModel {
        return super.fromObject(jsonObj, {
            childs: (item => {
                return (new MenuItem()).fromObject(item);
            })
        });
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get id_image(): number {
        return this._id_image;
    }

    set id_image(value: number) {
        this._id_image = value;
    }

    get image(): Image {
        return this._image;
    }

    set image(value: Image) {
        this._image = value;
    }

    get item_order(): number {
        return this._item_order;
    }

    set item_order(value: number) {
        this._item_order = value;
    }

    get linkType(): number {
        return this._linkType;
    }

    set linkType(value: number) {
        this._linkType = value;
    }

    get parent(): number {
        return this._parent;
    }

    set parent(value: number) {
        this._parent = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get link(): string {
        return this._link;
    }

    set link(value: string) {
        this._link = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get class(): string {
        return this._class;
    }

    set class(value: string) {
        this._class = value;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get childs(): MenuItem[] {
        return this._childs;
    }

    set childs(value: MenuItem[]) {
        this._childs = value;
    }

    get icon(): string {
        return this._icon;
    }

    set icon(value: string) {
        this._icon = value;
    }
}
